<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import moment from 'moment'
import { relationTypeList } from '@/utils/textFile'
import { getAction } from '@/command/netTool'

export default {
  name: 'evaluate',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/base/farmEvaluate/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '评论人',
          type: 'input',
          key: 'nickname'
        },
        {
          name: '评价时间',
          type: 'rangePicker',
          keys: ['evaluateStartDate', 'evaluateEndDate']
        },
        {
          name: 'ID',
          type: 'input',
          key: 'id'
        }
      ]
    },
    getColumns() {
      let that = this
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'createDate',
          title: '评价时间',
          align: 'left',
          sorter: (a, b) => moment(a.createDate).unix() - moment(b.createDate).unix()
        },
        {
          dataIndex: 'nickname',
          title: '评论人',
          align: 'left',
          customRender: function(text, records) {
            return (
              <div class="product-order-item">
                <img src={records.headImg} />
                <div>{records.nickname}</div>
              </div>
            )
          }
        },
        {
          dataIndex: 'content',
          title: '评论内容',
          align: 'left',
          sorter: (a, b) => {
            if (a.content && b.content) {
              return Number(a.content.length) - Number(b.content.length)
            }
          },
          customRender: function(text, records) {
            return <div>{text.substring(0, 50)}</div>
          }
        },
        {
          dataIndex: 'img',
          title: '评论图片',
          type: 'image',
          align: 'left',
          customRender: function(text, records) {
            let imgArr = text ? text.split(',') : []
            return <div class="product-order-item">{text ? imgArr.map(r => <img src={r} />) : ''}</div>
          }
        },
        {
          dataIndex: 'relationType',
          title: '评论来源',
          align: 'left',
          onExport: records => (relationTypeList.find(e => e.value == records) || { name: '' }).name,
          filterMultiple: false,
          filters: relationTypeList,
          customRender: records => (relationTypeList.find(e => e.value == records) || { name: '' }).name
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认删除吗?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/base/farmEvaluate?id=${records.id}`
                  })
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.delPut.call(this, {
                  url: '/base/farmEvaluate/deleteBatch',
                  params: { idList }
                })
              }
            }
          ]
        }
      ]
    },
    getDrawerForm(records) {
      const form = [
        {
          title: '基础信息',
          display: records.createDate,
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '时间',
                  value: records.createDate,
                  display: records.createDate
                },
                {
                  name: '评论人',
                  value: records.nickname,
                  display: records.nickname
                },
                {
                  name: '评论来源',
                  value: relationTypeList[records.relationType],
                  display: records.relationType
                }
              ].filter(e => e.display)
            }
          ]
        },
        {
          title: '内容',
          display: records.content,
          form: [
            {
              type: 'text',
              value: records.content
            }
          ]
        },
        {
          title: '图片',
          display: records.img,
          form: [
            {
              type: 'imageGroup',
              value: records.img
            }
          ]
        }
      ].filter(e => e.display)
      return form
    },
    onRowSelect(records) {
      getAction('/base/farmEvaluate/detail', { id: records.id }, '/api').then(res => {
        const data = this.getDrawerForm(res.data || records)
        apiTool.showDrawer({
          title: '详情',
          width: '700px',
          view: DrawerForm,
          viewProps: { data, form: records }
        })
      })
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        onSelectRow={this.onRowSelect}
        records={this.records}
      />
    )
  }
}
</script>
<style scoped lang="less">
.product-order-item {
  display: flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 5px;
    object-fit: cover;
  }
}
</style>
